<template>
  <v-container>
    <v-text-field
      class="search-for-projects"
      v-model="projectFilter"
      :label="$t('projectPairing.search')"
    >
      <template v-slot:append>
        <v-icon> mdi-magnify </v-icon>
      </template>
    </v-text-field>
    <v-card class="fixed-size">
      <v-row v-if="filteredProjects.length === 0">
        <v-col>
          <div class="no-projects">
            <p>{{ $t("projectPairing.noProjects") }}</p>
            <v-btn small @click="projectFilter = ''">
              {{ $t("projectPairing.clearFilter") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row v-for="project of filteredProjects" :key="project.id">
        <v-col>
          <div
            :key="project.id"
            @click="projectSelected(project)"
            :class="
              selected && selected.id === project.id
                ? 'selected project-box cursor-pointer'
                : 'deselected project-box cursor-pointer'
            "
          >
            {{ project.fullName }}
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: ["projectList", "selected", "allProjects"],
  data() {
    return {
      projectFilter: "",
    };
  },
  computed: {
    localProjectList() {
      const copy = JSON.parse(JSON.stringify(this.projectList));
      copy.map((p) => {
        p.fullName = this.subProjectName(p);
      });
      return copy.sort((a, b) => {
        return a.fullName > b.fullName;
      });
    },
    filteredProjects() {
      if (this.projectFilter.length !== 0) {
        return this.localProjectList.filter((p) => {
          return p.fullName
            .toUpperCase()
            .includes(this.projectFilter.toUpperCase());
        });
      } else {
        return this.localProjectList;
      }
    },
  },
  methods: {
    projectSelected(project) {
      this.$emit("project-selection-ready", project);
      this.projectFilter = "";
    },
    subProjectName(project) {
      if (project.parentProject) {
        const parent = this.allProjects.find((p) => {
          return p.id === project.parentProject;
        });
        return `${this.subProjectName(parent)} | ${project.name}`;
      } else {
        return project.name;
      }
    },
  },
};
</script>

<style>
.deselected {
  background-color: rgb(243, 243, 243);
}
.selected {
  /* background-color: lightblue; */
  background-color: #2196f3;
  color: white;
}

.project-box {
  height: 2em;
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 2%;
  margin-bottom: -3%;
  padding-left: 10px;
  padding-top: 0.25em;
  border: 1px rgb(150, 150, 150) solid;
  text-align: left;
  border-radius: 5px;
  box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
}

.search-for-projects {
  height: 3em;
  margin-left: 3%;
  margin-right: 3%;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-top: 0.25em;
}

.fixed-size {
  height: 300px;
  overflow: hidden;
  overflow-y: scroll;
}

.no-projects {
  margin-top: 30px;
  text-align: center;
}
</style>