<template>
  <v-container v-if="toolsConfigured">
    <v-card>
      <v-row no-gutters>
        <v-col>
          <div
            class="selector-header project-selector-margin project-selector-padding"
          >
            {{
              `${this.connectionBox.firstTool.tool} ${$t(
                "projectPairing.projectsHeader"
              )}`
            }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  @click="_fetchEverything(true, false)"
                  style="float: right"
                >
                  mdi-refresh</v-icon
                >
              </template>
              {{ $t("toolTips.reloadProjects") }}
            </v-tooltip>
          </div>
        </v-col>

        <v-col>
          <div
            class="selector-header project-selector-margin project-selector-padding"
          >
            {{
              `${this.connectionBox.secondTool.tool} ${$t(
                "projectPairing.projectsHeader"
              )}`
            }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  @click="_fetchEverything(false, true)"
                  style="float: right"
                >
                  mdi-refresh</v-icon
                >
              </template>
              {{ $t("toolTips.reloadProjects") }}
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row><p></p></v-row>
      <v-row no-gutters>
        <v-col v-if="!firstToolFetchError">
          <div class="project-selector-margin project-selector-padding">
            <v-autocomplete
              :items="firstToolCustomField"
              item-text="name"
              item-value="id"
              v-model="chosenCustFieldFirstTool"
              :value="chosenCustFieldFirstTool"
              :label="
                custFieldsDisabled
                  ? $t('projectPairing.disabledCustField')
                  : $t('projectPairing.custField')
              "
              dense
              :disabled="custFieldsDisabled"
              :loading="firstToolFetching"
              @change="firstToolCustomFieldSelected"
            >
              <template v-slot:append-outer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on"> mdi-help-circle-outline</v-icon>
                  </template>
                  {{ $t("toolTips.projectPairnigCustField") }}
                </v-tooltip>
              </template>
            </v-autocomplete>
          </div>
        </v-col>
        <v-col v-if="firstToolFetchError">
          <div class="project-selector-margin project-selector-padding">
            <p>{{ $t("projectPairing.errorLoadingTool") }}</p>
            <p>{{ $t("projectPairing.checkCredentials") }}</p>
          </div>
        </v-col>
        <v-col v-if="!secondToolFetchError">
          <div class="project-selector-margin project-selector-padding">
            <v-autocomplete
              :items="secondToolCustomField"
              item-text="name"
              item-value="id"
              v-model="chosenCustFieldSecondTool"
              :value="chosenCustFieldSecondTool"
              :label="
                custFieldsDisabled
                  ? $t('projectPairing.disabledCustField')
                  : $t('projectPairing.custField')
              "
              dense
              :loading="secondToolFetching"
              :disabled="custFieldsDisabled"
              @change="secondToolCustomFieldSelected"
            >
              <template v-slot:append-outer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on"> mdi-help-circle-outline</v-icon>
                  </template>
                  {{ $t("toolTips.projectPairnigCustField") }}
                </v-tooltip>
              </template>
            </v-autocomplete>
          </div>
        </v-col>
        <v-col v-if="secondToolFetchError">
          <div class="project-selector-margin project-selector-padding">
            <p>{{ $t("projectPairing.errorLoadingTool") }}</p>
            <p>{{ $t("projectPairing.checkCredentials") }}</p>
          </div>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        v-if="
          chosenCustFieldFirstTool !== null &&
          chosenCustFieldSecondTool !== null
        "
      >
        <v-col>
          <div
            class="project-selector-margin project-selector-padding selector-header"
            style="text-align: center"
          >
            {{ $t("projectPairing.selectPairsHeader") }}
          </div>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        v-if="
          chosenCustFieldFirstTool !== null &&
          chosenCustFieldSecondTool !== null
        "
      >
        <v-col>
          <div class="project-selector-margin project-selector-padding">
            <projects-selection
              key="firstSelection"
              :projectList="filteredFirstToolProjects"
              :selected="firstSelectedProject"
              :allProjects="firstToolProjects"
              @project-selection-ready="firstProjectSelected"
            >
            </projects-selection>
          </div>
        </v-col>
        <v-col cols="1" class="add-button-col" align="center">
          <v-btn
            class="add-button"
            @click="addPair()"
            color="info"
            elevation="2"
            :disabled="!addButtonActive"
          >
            {{ $t("projectPairing.addPairButton") }}
          </v-btn>
        </v-col>
        <v-col>
          <div class="project-selector-margin project-selector-padding">
            <projects-selection
              key="secondSelection"
              :projectList="filteredSecondToolProjects"
              :selected="secondSelectedProject"
              :allProjects="secondToolProjects"
              @project-selection-ready="secondProjectSelected"
            >
            </projects-selection>
          </div>
        </v-col>
      </v-row>
      <v-row
        v-if="
          chosenCustFieldFirstTool !== null &&
          chosenCustFieldSecondTool !== null
        "
      >
        <project-pairs
          :ProjectPairs="projectPairs"
          @pair-removed="removePair"
          class="project-selector-padding project-selector-margin"
        >
        </project-pairs>
      </v-row>
    </v-card>
  </v-container>
</template>


<script>
import ProjectsSelection from "./ProjectsSelection.vue";
import ProjectPairs from "./ProjectPairs.vue";
import axios from "axios";
import { Constants } from "@/constants/Constants";

export default {
  props: ["connectionBox", "toolsConfigured"],
  data() {
    return {
      chosenCustFieldFirstTool: null,
      chosenCustFieldSecondTool: null,
      custFieldsDisabled: false,

      firstToolCustomField: [],
      secondToolCustomField: [],

      firstToolProjects: [],
      secondToolProjects: [],

      firstSelectedProject: null,
      secondSelectedProject: null,

      addButtonActive: false,

      projectPairs: [],

      firstToolFetchError: false,
      secondToolFetchError: false,

      firstToolFetching: true,
      secondToolFetching: true,
    };
  },
  computed: {
    filteredFirstToolProjects() {
      let filteredOnCustomField = this.firstToolProjects;
      if (this.chosenCustFieldFirstTool) {
        filteredOnCustomField = this.firstToolProjects.filter((p) => {
          return p.customFields.find((c) => {
            return c.id === this.chosenCustFieldFirstTool.id;
          });
        });
      }
      return filteredOnCustomField.filter((p) => {
        const found = this.projectPairs.find((pp) => {
          return p.id === pp.firstProject.id;
        });
        return found ? false : true;
      });
    },
    filteredSecondToolProjects() {
      let filteredOnCustomField = this.secondToolProjects;
      if (this.chosenCustFieldSecondTool) {
        filteredOnCustomField = this.secondToolProjects.filter((p) => {
          return p.customFields.find((c) => {
            return c.id === this.chosenCustFieldSecondTool.id;
          });
        });
      }
      return filteredOnCustomField.filter((p) => {
        return !this.projectPairs.find((pp) => {
          return p.id === pp.secondProject.id;
        });
      });
    },
  },
  methods: {
    addPair() {
      const pair = {
        firstProject: {
          id: this.firstSelectedProject.id,
          name: this.firstSelectedProject.name,
        },
        secondProject: {
          id: this.secondSelectedProject.id,
          name: this.secondSelectedProject.name,
        },
        id: `${this.firstSelectedProject.id}_${this.secondSelectedProject.id}`,
      };
      this.projectPairs.push(pair);
      this.connectionBox.projectPairs = this.projectPairs;
      this.firstSelectedProject = null;
      this.secondSelectedProject = null;
      this.dispatchConnectionBox();
      this.enableAddButton();
    },

    removePair(pair) {
      this.projectPairs = this.projectPairs.filter((p) => {
        return p.id !== pair.id;
      });
      this.connectionBox.projectPairs = this.projectPairs;
      this.dispatchConnectionBox();
    },

    firstToolCustomFieldSelected(chosen) {
      const custField = this.firstToolCustomField.find((c) => {
        return c.id == chosen;
      });
      this.connectionBox.firstTool.customField = custField;
      this.chosenCustFieldFirstTool = this.firstToolCustomField.find((f) => {
        return f.id == chosen;
      });
      this.enableSaveButton();
    },
    secondToolCustomFieldSelected(chosen) {
      const custField = this.secondToolCustomField.find((c) => {
        return c.id == chosen;
      });
      this.connectionBox.secondTool.customField = custField;
      this.chosenCustFieldSecondTool = this.secondToolCustomField.find((f) => {
        return f.id == chosen;
      });
      this.enableSaveButton();
    },

    enableSaveButton() {
      if (this.chosenCustFieldFirstTool && this.chosenCustFieldSecondTool) {
        this.$emit("pairs-configured", true);
      }
    },

    firstProjectSelected(project) {
      this.firstSelectedProject = project;
      this.enableAddButton();
    },

    secondProjectSelected(project) {
      this.secondSelectedProject = project;
      this.enableAddButton();
    },

    enableAddButton() {
      if (
        this.firstSelectedProject !== null &&
        this.secondSelectedProject !== null
      )
        this.addButtonActive = true;
      else this.addButtonActive = false;
    },

    async _fetchEverything(first = true, second = true) {
      this.firstToolFetchError = false;
      this.secondToolFetchError = false;
      this.firstToolFetching = true;
      this.secondToolFetching = true;
      if (first) await this._fetchFirstTool();
      if (second) await this._fetchSecondTool();
      this._fillCustomFields();
      this._addNamesToProjectPairsFromDB();
      this.projectPairs = this.connectionBox.projectPairs;
    },

    _fillCustomFields() {
      if (this.connectionBox.firstTool.customField) {
        this.chosenCustFieldFirstTool =
          this.connectionBox.firstTool.customField;
      }
      if (this.connectionBox.secondTool.customField) {
        this.chosenCustFieldSecondTool =
          this.connectionBox.secondTool.customField;
      }
      if (
        this.connectionBox.firstTool.customField &&
        this.connectionBox.secondTool.customField
      ) {
        this.custFieldsDisabled = true;
        this.enableSaveButton();
      }
    },
    _addNamesToProjectPairsFromDB() {
      if (this.connectionBox.projectPairs) {
        this.projectPairs = this.connectionBox.projectPairs;
        this.projectPairs.forEach((p) => {
          p.firstProject.name = this.firstToolProjects.find((ftp) => {
            return ftp.id === p.firstProject.id;
          }).name;
          p.secondProject.name = this.secondToolProjects.find((stp) => {
            return stp.id === p.secondProject.id;
          }).name;
        });
      } else {
        this.connectionBox.projectPairs = [];
      }
    },

    async _fetchFirstTool() {
      if (this.connectionBox.firstTool.tool === "Jira") {
        await this._fetchJira(1);
      } else if (this.connectionBox.firstTool.tool === "Redmine") {
        await this._fetchRedmine(1);
      } else {
        this.firstToolProjects = [];
        this.firstToolCustomField = [];
      }
    },
    async _fetchSecondTool() {
      if (this.connectionBox.secondTool.tool === "Jira") {
        await this._fetchJira(2);
      } else if (this.connectionBox.secondTool.tool === "Redmine") {
        await this._fetchRedmine(2);
      } else {
        this.secondToolProjects = [];
        this.secondToolCustomField = [];
      }
    },

    async _fetchJira(toolNum) {
      toolNum === 1
        ? (this.firstToolFetching = true)
        : (this.secondToolFetching = true);
      const serverAuthData = await this._getServerAuth();
      serverAuthData.params = {
        api_key:
          toolNum === 1
            ? this.connectionBox.firstTool.jiraApiKey
            : this.connectionBox.secondTool.jiraApiKey,
        domain:
          toolNum === 1
            ? this.connectionBox.firstTool.jiraDomain
            : this.connectionBox.secondTool.jiraDomain,
        user_email:
          toolNum === 1
            ? this.connectionBox.firstTool.jiraUserEmail
            : this.connectionBox.secondTool.jiraUserEmail,
      };
      let projects = [];
      let customFields = [];
      await axios
        .get(
          `${Constants.BACKEND_API_URL}synced_services_config/jira_projects`,
          serverAuthData
        )
        .then((response) => {
          toolNum === 1
            ? (this.firstToolFetchError = false)
            : (this.secondToolFetchError = false);
          projects = response.data.projects;
          customFields = response.data.customFields;
        })
        .catch((e) => {
          toolNum === 1
            ? (this.firstToolFetchError = true)
            : (this.secondToolFetchError = true);
        });
      if (toolNum === 1) {
        this.firstToolProjects = projects;
        this.firstToolCustomField = customFields;
        this.firstToolFetching = false;
      } else {
        // toolnum === 2
        this.secondToolProjects = projects;
        this.secondToolCustomField = customFields;
        this.secondToolFetching = false;
      }
    },

    async _fetchRedmine(toolNum) {
      toolNum === 1
        ? (this.firstToolFetching = true)
        : (this.secondToolFetching = true);
      const serverAuthData = await this._getServerAuth();
      serverAuthData.params = {
        api_key:
          toolNum === 1
            ? this.connectionBox.firstTool.redmineApiKey
            : this.connectionBox.secondTool.redmineApiKey,
        api_point:
          toolNum === 1
            ? this.connectionBox.firstTool.redmineApiPoint
            : this.connectionBox.secondTool.redmineApiPoint,
      };
      let projects = [];
      let customFields = [];
      await axios
        .get(
          `${Constants.BACKEND_API_URL}synced_services_config/redmine_projects`,
          serverAuthData
        )
        .then((response) => {
          projects = response.data.projects;
          customFields = response.data.customFields;
          toolNum === 1
            ? (this.firstToolFetchError = false)
            : (this.secondToolFetchError = false);
        })
        .catch((e) => {
          toolNum === 1
            ? (this.firstToolFetchError = true)
            : (this.secondToolFetchError = true);
        });
      if (toolNum === 1) {
        this.firstToolProjects = projects;
        this.firstToolCustomField = customFields;
        this.firstToolFetching = false;
      } else {
        // toolnum === 2
        this.secondToolProjects = projects;
        this.secondToolCustomField = customFields;
        this.secondToolFetching = false;
      }
    },

    async _getServerAuth() {
      const accessToken = await this.$auth.getTokenSilently();
      return {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
    },
    dispatchConnectionBox() {
      const connectionBoxes = this.$store.state.userConnectionBoxes;
      for (let pos = 0; pos < connectionBoxes.length; ++pos) {
        if (
          connectionBoxes[pos].connectionBoxId ===
          this.connectionBox.connectionBoxId
        ) {
          connectionBoxes[pos].projectPairs = this.projectPairs;
          connectionBoxes[pos].firstTool.customField =
            this.firstToolCustomField;
          connectionBoxes[pos].secondTool.customField =
            this.secondToolCustomField;
        }
        break;
      }
      this.$store.dispatch("changeUserConnectionBoxes", connectionBoxes);
    },
  },
  components: {
    "projects-selection": ProjectsSelection,
    "project-pairs": ProjectPairs,
  },
  async created() {
    await this._fetchEverything();
  },
  watch: {
    toolsConfigured: {
      immediate: true,
      handler() {
        this._fetchEverything();
      },
    },
  },
};
</script>


<style>
.project-selector-margin {
  margin-left: 8px;
  margin-right: 8px;
}
.project-selector-padding {
  padding-left: 16px;
  padding-right: 16px;
}

.selector-header {
  height: 3em;
  margin-top: 4px;
  padding-top: 0.75em;
  background-color: rgb(243, 243, 243);
  text-align: left;
  border-radius: 5px;
}

.add-button {
  width: min-content;
  border: solid black 1px;
  align-content: center;
  position: relative;
  top: 50%;
  /* margin-top: 170px; */
}

.add-button-col {
  /* border: solid blue 4px; */
  align-content: center;
}

.cursor-pointer {
  cursor: pointer;
}
</style>