var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.toolsConfigured)?_c('v-container',[_c('v-card',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{staticClass:"selector-header project-selector-margin project-selector-padding"},[_vm._v(" "+_vm._s(`${this.connectionBox.firstTool.tool} ${_vm.$t( "projectPairing.projectsHeader" )}`)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticStyle:{"float":"right"},on:{"click":function($event){return _vm._fetchEverything(true, false)}}},on),[_vm._v(" mdi-refresh")])]}}],null,false,3094618465)},[_vm._v(" "+_vm._s(_vm.$t("toolTips.reloadProjects"))+" ")])],1)]),_c('v-col',[_c('div',{staticClass:"selector-header project-selector-margin project-selector-padding"},[_vm._v(" "+_vm._s(`${this.connectionBox.secondTool.tool} ${_vm.$t( "projectPairing.projectsHeader" )}`)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticStyle:{"float":"right"},on:{"click":function($event){return _vm._fetchEverything(false, true)}}},on),[_vm._v(" mdi-refresh")])]}}],null,false,3445053985)},[_vm._v(" "+_vm._s(_vm.$t("toolTips.reloadProjects"))+" ")])],1)])],1),_c('v-row',[_c('p')]),_c('v-row',{attrs:{"no-gutters":""}},[(!_vm.firstToolFetchError)?_c('v-col',[_c('div',{staticClass:"project-selector-margin project-selector-padding"},[_c('v-autocomplete',{attrs:{"items":_vm.firstToolCustomField,"item-text":"name","item-value":"id","value":_vm.chosenCustFieldFirstTool,"label":_vm.custFieldsDisabled
                ? _vm.$t('projectPairing.disabledCustField')
                : _vm.$t('projectPairing.custField'),"dense":"","disabled":_vm.custFieldsDisabled,"loading":_vm.firstToolFetching},on:{"change":_vm.firstToolCustomFieldSelected},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline")])]}}],null,false,795624704)},[_vm._v(" "+_vm._s(_vm.$t("toolTips.projectPairnigCustField"))+" ")])]},proxy:true}],null,false,4176950160),model:{value:(_vm.chosenCustFieldFirstTool),callback:function ($$v) {_vm.chosenCustFieldFirstTool=$$v},expression:"chosenCustFieldFirstTool"}})],1)]):_vm._e(),(_vm.firstToolFetchError)?_c('v-col',[_c('div',{staticClass:"project-selector-margin project-selector-padding"},[_c('p',[_vm._v(_vm._s(_vm.$t("projectPairing.errorLoadingTool")))]),_c('p',[_vm._v(_vm._s(_vm.$t("projectPairing.checkCredentials")))])])]):_vm._e(),(!_vm.secondToolFetchError)?_c('v-col',[_c('div',{staticClass:"project-selector-margin project-selector-padding"},[_c('v-autocomplete',{attrs:{"items":_vm.secondToolCustomField,"item-text":"name","item-value":"id","value":_vm.chosenCustFieldSecondTool,"label":_vm.custFieldsDisabled
                ? _vm.$t('projectPairing.disabledCustField')
                : _vm.$t('projectPairing.custField'),"dense":"","loading":_vm.secondToolFetching,"disabled":_vm.custFieldsDisabled},on:{"change":_vm.secondToolCustomFieldSelected},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline")])]}}],null,false,795624704)},[_vm._v(" "+_vm._s(_vm.$t("toolTips.projectPairnigCustField"))+" ")])]},proxy:true}],null,false,4176950160),model:{value:(_vm.chosenCustFieldSecondTool),callback:function ($$v) {_vm.chosenCustFieldSecondTool=$$v},expression:"chosenCustFieldSecondTool"}})],1)]):_vm._e(),(_vm.secondToolFetchError)?_c('v-col',[_c('div',{staticClass:"project-selector-margin project-selector-padding"},[_c('p',[_vm._v(_vm._s(_vm.$t("projectPairing.errorLoadingTool")))]),_c('p',[_vm._v(_vm._s(_vm.$t("projectPairing.checkCredentials")))])])]):_vm._e()],1),(
        _vm.chosenCustFieldFirstTool !== null &&
        _vm.chosenCustFieldSecondTool !== null
      )?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{staticClass:"project-selector-margin project-selector-padding selector-header",staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t("projectPairing.selectPairsHeader"))+" ")])])],1):_vm._e(),(
        _vm.chosenCustFieldFirstTool !== null &&
        _vm.chosenCustFieldSecondTool !== null
      )?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{staticClass:"project-selector-margin project-selector-padding"},[_c('projects-selection',{key:"firstSelection",attrs:{"projectList":_vm.filteredFirstToolProjects,"selected":_vm.firstSelectedProject,"allProjects":_vm.firstToolProjects},on:{"project-selection-ready":_vm.firstProjectSelected}})],1)]),_c('v-col',{staticClass:"add-button-col",attrs:{"cols":"1","align":"center"}},[_c('v-btn',{staticClass:"add-button",attrs:{"color":"info","elevation":"2","disabled":!_vm.addButtonActive},on:{"click":function($event){return _vm.addPair()}}},[_vm._v(" "+_vm._s(_vm.$t("projectPairing.addPairButton"))+" ")])],1),_c('v-col',[_c('div',{staticClass:"project-selector-margin project-selector-padding"},[_c('projects-selection',{key:"secondSelection",attrs:{"projectList":_vm.filteredSecondToolProjects,"selected":_vm.secondSelectedProject,"allProjects":_vm.secondToolProjects},on:{"project-selection-ready":_vm.secondProjectSelected}})],1)])],1):_vm._e(),(
        _vm.chosenCustFieldFirstTool !== null &&
        _vm.chosenCustFieldSecondTool !== null
      )?_c('v-row',[_c('project-pairs',{staticClass:"project-selector-padding project-selector-margin",attrs:{"ProjectPairs":_vm.projectPairs},on:{"pair-removed":_vm.removePair}})],1):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }