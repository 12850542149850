<template>
  <v-container>
    <v-card>
      <v-row>
        <v-col>
          <div
            class="project-selector-padding project-selector-margin selector-header"
          >
            {{ $t("projectPairing.selectedPairs") }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  @click="_fetchEverything(true, false)"
                  style="float: right"
                >
                  mdi-help-circle-outline</v-icon
                >
              </template>
              {{ $t("toolTips.projectPairs") }}
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <div class="max-size">
        <v-row v-if="ProjectPairs.length === 0">
          <v-col class="pair-row no-projects">
            {{ $t("projectPairing.noPairs") }}</v-col
          >
        </v-row>
        <v-row v-for="pair of ProjectPairs" :key="pair.id" class="pair-row">
          <v-col class="project-box">
            {{ pair.firstProject.name }}
          </v-col>
          <v-col cols="1" align="center">
            <div
              @click="removePair(pair)"
              class="del-pair-button cursor-pointer"
            >
              <v-icon color="error" v-on="on">mdi-delete</v-icon>
            </div>
          </v-col>
          <v-col class="project-box">
            {{ pair.secondProject.name }}
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: ["ProjectPairs"],
  methods: {
    removePair(pair) {
      this.$emit("pair-removed", pair);
    },
  },
};
</script>

<style>
.max-size {
  min-height: 100px;
  max-height: 200px;
  overflow: hidden;
  overflow-y: scroll;
}

.del-pair-button {
  height: 2em;
  margin-top: 15%;
  margin-bottom: -3%;
}
.pair-row {
  border-bottom: 1px solid lightgray;
}
</style>